import { createRouter, createWebHashHistory } from 'vue-router'

import HomePage from '../page/Home-Page.vue'

const routes = [
    // ? home page show
    { path: '/', name: '', component: HomePage },

]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

export default router