<template lang="">
  <div>
    <header id="header" class="header fixed-top">
      <div class="topbar d-flex align-items-center">
        <div class="container d-flex justify-content-center justify-content-md-between">
          <div class="contact-info d-flex align-items-center">
            <i class="bi bi-envelope d-flex align-items-center"
              ><a href="mailto:contact@helathel.com">contact@helathel.com</a></i
            >
            <i class="bi bi-phone d-flex align-items-center ms-4">
              <a href="tel:+94765903122" class="d-flex align-items-center text-decoration-none">
                <span>+94 765903122</span>
              </a>
            </i>
          </div>
          <div class="social-links d-none d-md-flex align-items-center">
            <a href="#" class="twitter"><i class="bi bi-twitter-x"></i></a>
            <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
            <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
            <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
          </div>
        </div>
      </div>
      <!-- End Top Bar -->

      <div class="branding d-flex align-items-cente">
        <div class="container position-relative d-flex align-items-center justify-content-between">
          <a href="index.html" class="logo d-flex align-items-center">
            <!-- Uncomment the line below if you also wish to use an image logo -->
            <img src="assets/img/logo/logo.png" style="width: 150px !important; height: 150px !important" />
          </a>

          <nav id="navmenu" class="navmenu">
            <ul>
              <li>
                <a href="#hero" class="active">Home<br /></a>
              </li>
              <li><a href="#about">About</a></li>
              <li><a href="#services">Services</a></li>
              <li><a href="#team">Products</a></li>
              <li><a href="#contact">Contact</a></li>
            </ul>
            <i class="mobile-nav-toggle d-xl-none bi bi-list"></i>
          </nav>
        </div>
      </div>
    </header>

    <main class="main">
      <!-- Hero Section -->
      <section id="hero" class="hero section">
        <div class="container position-relative" data-aos="fade-up" data-aos-delay="100">
          <div class="row gy-5 justify-content-between">
            <div class="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h2><span>Welcome to </span><span class="accent">Helathel</span></h2>
              <p>Holistic Health Starts Here Discover the Power of Hela Thel.</p>
              <div class="d-flex">
                <a href="#about" class="btn-get-started">Who We Are</a>
                <a href="" class="glightbox btn-watch-video d-flex align-items-center"
                  ><i class="bi bi-play-circle"></i><span>Watch Video</span></a
                >
              </div>
            </div>
            <div class="col-lg-5 order-1 order-lg-2">
              <img src="assets/img/hero-img.svg" class="img-fluid" alt="" />
            </div>
          </div>
        </div>

        <div class="icon-boxes position-relative" data-aos="fade-up" data-aos-delay="200">
          <div class="container position-relative">
            <div class="row gy-4 mt-5">
              <div class="col-xl-3 col-md-6">
                <div class="icon-box p-3 rounded">
                  <div class="icon"><i class="fas fa-leaf fa-1x"></i></div>
                  <h4 class="title"><a href="" class="stretched-link">Natural</a></h4>
                </div>
              </div>
              <!--End Icon Box -->

              <div class="col-xl-3 col-md-6">
                <div class="icon-box p-3 rounded">
                  <div class="icon"><i class="fas fa-vials fa-1x"></i></div>
                  <h4 class="title"><a href="" class="stretched-link">Chemical Less</a></h4>
                </div>
              </div>
              <!--End Icon Box -->

              <div class="col-xl-3 col-md-6">
                <div class="icon-box p-3 rounded">
                  <div class="icon"><i class="fas fa-shield-alt fa-1x"></i></div>
                  <h4 class="title"><a href="" class="stretched-link">Safely Made</a></h4>
                </div>
              </div>
              <!--End Icon Box -->

              <div class="col-xl-3 col-md-6">
                <div class="icon-box p-3 rounded">
                  <div class="icon"><i class="fa-solid fa-bottle-droplet fa-1x"></i></div>
                  <h4 class="title"><a href="" class="stretched-link">Helathel</a></h4>
                </div>
              </div>
              <!--End Icon Box -->
            </div>
          </div>
        </div>
      </section>
      <!-- /Hero Section -->

      <!-- About Section -->
      <section id="about" class="about section">
        <!-- Section Title -->
        <div class="container section-title" data-aos="fade-up">
          <h2>Who We Are<br /></h2>
          <p>Experience Pure Nature with Hela Thel</p>
        </div>
        <!-- End Section Title -->

        <div class="container">
          <div class="row gy-4">
            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
              <h3 class="text-center">Our Vision</h3>
              <img src="assets/img/about/vision-image.jpg" class="img-fluid rounded-4 mb-4" alt="" />
              <p class="text-center">
                At <b>HELATHEL</b> Online, we envision a future where our online marketplace is the go-to destination
                for consumers seeking high-quality products at reasonable prices. We strive to create a seamless and
                trustworthy shopping experience that not only drives profits for our parent company but also adds
                significant value to our brand constituents.
              </p>
              <p class="text-center">
                Our vision is to foster long-term customer loyalty through continuous innovation and a steadfast
                commitment to quality, ultimately establishing <b>HELATHEL</b> Online as a leader in the e-commerce
                industry.
              </p>
            </div>
            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="250">
              <h3 class="text-center">Our Mission</h3>

              <p class="text-center">
                At <b>HELATHEL</b> Online, our mission is to provide a reliable and accessible online marketplace that
                offers high-quality products at reasonable prices. We are committed to driving profits for our parent
                company while simultaneously adding significant value to our brand constituents. Our goal is to create a
                seamless shopping experience that meets the diverse needs of our customers, fosters trust, and promotes
                long-term loyalty. By prioritizing customer satisfaction and maintaining a strong focus on quality, we
                aim to build a reputable brand that stands out in the marketplace.
              </p>
              <p class="text-center">
                We believe in the power of innovation and continuous improvement to enhance our offerings and deliver
                exceptional value to our customers.
              </p>
              <img src="assets/img/about/mission-image.jpg" class="img-fluid rounded-4 mb-4" alt="" />
            </div>
          </div>
        </div>
      </section>
      <!-- /About Section -->
      <!-- Services Section -->
      <section id="services" class="services section">
        <!-- Section Title -->
        <div class="container section-title" data-aos="fade-up">
          <h2>Our Services</h2>
          <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p>
        </div>
        <!-- End Section Title -->

        <div class="container">
          <div class="row gy-4">
            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
              <div class="service-item position-relative">
                <div class="icon">
                  <i class="bi bi-activity"></i>
                </div>
                <h3>Nesciunt Mete</h3>
                <p>
                  Provident nihil minus qui consequatur non omnis maiores. Eos accusantium minus dolores iure
                  perferendis tempore et consequatur.
                </p>
              </div>
            </div>
            <!-- End Service Item -->

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
              <div class="service-item position-relative">
                <div class="icon">
                  <i class="bi bi-broadcast"></i>
                </div>
                <h3>Eosle Commodi</h3>
                <p>
                  Ut autem aut autem non a. Sint sint sit facilis nam iusto sint. Libero corrupti neque eum hic non ut
                  nesciunt dolorem.
                </p>
              </div>
            </div>
            <!-- End Service Item -->

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
              <div class="service-item position-relative">
                <div class="icon">
                  <i class="bi bi-easel"></i>
                </div>
                <h3>Ledo Markt</h3>
                <p>
                  Ut excepturi voluptatem nisi sed. Quidem fuga consequatur. Minus ea aut. Vel qui id voluptas adipisci
                  eos earum corrupti.
                </p>
              </div>
            </div>
            <!-- End Service Item -->

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
              <div class="service-item position-relative">
                <div class="icon">
                  <i class="bi bi-bounding-box-circles"></i>
                </div>
                <h3>Asperiores Commodit</h3>
                <p>
                  Non et temporibus minus omnis sed dolor esse consequatur. Cupiditate sed error ea fuga sit provident
                  adipisci neque.
                </p>
              </div>
            </div>
            <!-- End Service Item -->

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
              <div class="service-item position-relative">
                <div class="icon">
                  <i class="bi bi-calendar4-week"></i>
                </div>
                <h3>Velit Doloremque</h3>
                <p>
                  Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem
                  alias eius labore.
                </p>
              </div>
            </div>
            <!-- End Service Item -->

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
              <div class="service-item position-relative">
                <div class="icon">
                  <i class="bi bi-chat-square-text"></i>
                </div>
                <h3>Dolori Architecto</h3>
                <p>
                  Hic molestias ea quibusdam eos. Fugiat enim doloremque aut neque non et debitis iure. Corrupti
                  recusandae ducimus enim.
                </p>
              </div>
            </div>
            <!-- End Service Item -->
          </div>
        </div>
      </section>
      <!-- /Services Section -->
      <!-- Team Section -->

      <!-- /Team Section -->
      <!-- Faq Section -->
      <section id="faq" class="faq section">
        <div class="container">
          <div class="row gy-4">
            <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
              <div class="content px-xl-5">
                <h3><span>Frequently Asked </span><strong>Questions</strong></h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Duis aute irure dolor in reprehenderit
                </p>
              </div>
            </div>

            <div class="col-lg-8" data-aos="fade-up" data-aos-delay="200">
              <div class="faq-container">
                <div class="faq-item faq-active">
                  <h3><span class="num">1.</span> <span>Non consectetur a erat nam at lectus urna duis?</span></h3>
                  <div class="faq-content">
                    <p>
                      Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur
                      gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.
                    </p>
                  </div>
                  <i class="faq-toggle bi bi-chevron-right"></i>
                </div>
                <!-- End Faq item-->

                <div class="faq-item">
                  <h3>
                    <span class="num">2.</span>
                    <span>Feugiat scelerisque varius morbi enim nunc faucibus a pellentesque?</span>
                  </h3>
                  <div class="faq-content">
                    <p>
                      Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet
                      id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est
                      pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt
                      dui.
                    </p>
                  </div>
                  <i class="faq-toggle bi bi-chevron-right"></i>
                </div>
                <!-- End Faq item-->

                <div class="faq-item">
                  <h3>
                    <span class="num">3.</span> <span>Dolor sit amet consectetur adipiscing elit pellentesque?</span>
                  </h3>
                  <div class="faq-content">
                    <p>
                      Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Faucibus pulvinar
                      elementum integer enim. Sem nulla pharetra diam sit amet nisl suscipit. Rutrum tellus pellentesque
                      eu tincidunt. Lectus urna duis convallis convallis tellus. Urna molestie at elementum eu facilisis
                      sed odio morbi quis
                    </p>
                  </div>
                  <i class="faq-toggle bi bi-chevron-right"></i>
                </div>
                <!-- End Faq item-->

                <div class="faq-item">
                  <h3>
                    <span class="num">4.</span> <span>Ac odio tempor orci dapibus. Aliquam eleifend mi in nulla?</span>
                  </h3>
                  <div class="faq-content">
                    <p>
                      Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet
                      id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est
                      pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt
                      dui.
                    </p>
                  </div>
                  <i class="faq-toggle bi bi-chevron-right"></i>
                </div>
                <!-- End Faq item-->

                <div class="faq-item">
                  <h3>
                    <span class="num">5.</span>
                    <span>Tempus quam pellentesque nec nam aliquam sem et tortor consequat?</span>
                  </h3>
                  <div class="faq-content">
                    <p>
                      Molestie a iaculis at erat pellentesque adipiscing commodo. Dignissim suspendisse in est ante in.
                      Nunc vel risus commodo viverra maecenas accumsan. Sit amet nisl suscipit adipiscing bibendum est.
                      Purus gravida quis blandit turpis cursus in
                    </p>
                  </div>
                  <i class="faq-toggle bi bi-chevron-right"></i>
                </div>
                <!-- End Faq item-->
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- /Faq Section -->

      <!-- Recent Posts Section -->

      <!-- /Recent Posts Section -->

      <!-- Contact Section -->
      <section id="contact" class="contact section">
        <!-- Section Title -->
        <div class="container section-title" data-aos="fade-up">
          <h2>Contact</h2>
          <p>Contact US For More Details</p>
        </div>
        <!-- End Section Title -->

        <div class="container" data-aos="fade-up" data-aos-delay="100">
          <div class="row gx-lg-0 gy-4">
            <div class="col-lg-4">
              <div class="info-container d-flex flex-column align-items-center justify-content-center">
                <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="200">
                  <i class="bi bi-geo-alt flex-shrink-0"></i>
                  <div>
                    <h3>Address</h3>
                    <p>Mukalana, Samarapoththa, Pannala, Sri Lanka ZIP - 60160</p>
                  </div>
                </div>
                <!-- End Info Item -->

                <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
                  <i class="bi bi-telephone flex-shrink-0"></i>
                  <div>
                    <h3>Call Us</h3>
                    <p>+94 765903122</p>
                  </div>
                </div>
                <!-- End Info Item -->

                <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
                  <i class="bi bi-envelope flex-shrink-0"></i>
                  <div>
                    <h3>Email Us</h3>
                    <p>contact@helathel.com</p>
                  </div>
                </div>
                <!-- End Info Item -->

                <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="500">
                  <i class="bi bi-clock flex-shrink-0"></i>
                  <div>
                    <h3>Open Hours</h3>
                    <p>Mon-Sat: 8.00 AM - 6.00 PM</p>
                  </div>
                </div>
                <!-- End Info Item -->
              </div>
            </div>

            <div class="col-lg-8">
              <form
                action="forms/contact.php"
                method="post"
                class="php-email-form"
                data-aos="fade"
                data-aos-delay="100"
              >
                <div class="row gy-4">
                  <div class="col-md-6">
                    <input type="text" name="name" class="form-control" placeholder="Your Name" required="" />
                  </div>

                  <div class="col-md-6">
                    <input type="email" class="form-control" name="email" placeholder="Your Email" required="" />
                  </div>

                  <div class="col-md-12">
                    <input type="text" class="form-control" name="subject" placeholder="Subject" required="" />
                  </div>

                  <div class="col-md-12">
                    <textarea class="form-control" name="message" rows="8" placeholder="Message" required=""></textarea>
                  </div>

                  <div class="col-md-12 text-center">
                    <div class="loading">Loading</div>
                    <div class="error-message"></div>
                    <div class="sent-message">Your message has been sent. Thank you!</div>

                    <button type="submit">Send Message</button>
                  </div>
                </div>
              </form>
            </div>
            <!-- End Contact Form -->
          </div>
        </div>
      </section>
      <!-- /Contact Section -->
      <!-- Product Section - Start -->
    </main>

    <footer id="footer" class="footer accent-background">
      <div class="container footer-top">
        <div class="row gy-4">
          <div class="col-12 footer-about text-center d-flex flex-column align-items-center">
            <a href="index.html" class="col-lg-5 order-1 order-lg-2">
              <!-- Uncomment the line below if you also wish to use an image logo -->
              <img src="assets/img/hero-img.svg" class="img-fluid" style="width: 50%" />
            </a>
            <p class="mt-4">
              Cras fermentum odio eu feugiat lide par naso tierra. Justo eget nada terra videa magna derita valies darta
              donna mare fermentum iaculis eu non diam phasellus.
            </p>
            <div class="social-links d-flex justify-content-center mt-4">
              <a href=""><i class="bi bi-twitter-x"></i></a>
              <a href=""><i class="bi bi-facebook"></i></a>
              <a href=""><i class="bi bi-instagram"></i></a>
              <a href=""><i class="bi bi-linkedin"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="container copyright text-center mt-4">
        <p>© <span>Copyright</span> <strong class="px-1 sitename">Impact</strong> <span>All Rights Reserved</span></p>
        <div class="credits">Powered by <a href="https://hashloopit.com/">Hashloop IT Solutions</a></div>
      </div>
    </footer>

    <!-- Scroll Top -->
    <a href="#" id="scroll-top" class="scroll-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>
<script>
export default {
  name: "HomePage",
};
</script>
